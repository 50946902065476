<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-card class="mx-auto rounded-lg" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">TOTAL EVENTOS INFORMADOS</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">{{ dashboardData.total }}</h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-account-injury</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">EVENTOS EN VERIFICACIÓN</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">
                  {{ dashboardData.total_by_state?.verification || '' }}
                </h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-eye</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">EVENTOS PENDIENTES DE ASIGNACIÓN</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">{{ dashboardData.total_without_state }}</h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-account-check</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="pa-5">
            <v-toolbar flat>
              <v-toolbar-title>ÚLTIMOS EVENTOS INFORMADOS</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                rounded
                class="mb-2"
                :to="{
                  name: 'womenRights.list',
                }"
              >
                Ver todos
              </v-btn>
            </v-toolbar>

            <div class="mt-4">
              <template>
                <v-data-table
                  :footer-props="{
                    'items-per-page-text': 'Filas por página',
                    'items-per-page-options': [5,10,15]
                    
                  }"
                  class="elevation-1"
                  :headers="headers"
                  :items="events"
                  :items-per-page="6"
                  item-key="id"
                  :server-items-length="filtrado?.count"
                  v-on:update:options="onPaginate"
                >
                  <template v-slot:item.state="{ item }">
                    <v-chip outlined :color="eventStateColor(item.state.name)">
                      {{ item.state.name || "SIN ESTADO ASIGNADO" }}
                    </v-chip>
                  </template>
                </v-data-table>
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import states from "../../enums/eventStates";
import {getDashboardData, listEvents} from "@/services/humansRights";
import getEventStateColor from "../../utils/eventStateColors";

export default {
  data() {
    return {
      dashboardData: {},
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "TIPO DE EVENTO", value: "type.name", sortable: false },
        { text: "TERRITORIO", value: "territory.descripcion", sortable: false },
        { text: "FECHA", value: "date" },
        {
          text: "NO. AFECTADOS",
          value: "affectedPeoplesCount",
          sortable: false,
        },
        { text: "COORDINADOR", value: "userAssigned", sortable: false },
        { text: "ESTADO", value: "state", sortable: false },
      ],
      events: [],
      filtrado: {},
    };
  },
  methods: {
    async list(options) {
      try {
        this.showLoader();
        const listEventsResponse = (await listEvents(options))?.data;
        this.events = listEventsResponse?.results
          .map((x) => {
            return {
              id: x.id,
              type: x.event_type,
              territory: x.territory,
              date: x.date,
              description: x.description,
              affectedPeoplesCount: x.affected.length,
              userAssigned: x.current_state.user
                ? `${x.current_state.user.first_name} ${x.current_state.user.last_name}`
                : "Sin Asignar",
              state: x.current_state.state,
              isFemale: x.is_female,
            };
          });
        this.hideLoader();
        return listEventsResponse;
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    getCurrentFilter() {
      return {
        ...(this.search && { search: this.search }),
      };
    },
    onPaginate: async function (
      pagination = { sortBy: [], sortDesc: [], itemsPerPage: 15, page: 1 }
    ) {
      const { sortBy, sortDesc, itemsPerPage, page } = pagination;
      this.currentItemsPerPage = itemsPerPage;
      this.filtrado = await this.list({
        page,
        itemsPerPage,
        isFemale: 'true',
        ...this.getCurrentFilter(),
        ...this.filters,
        ...(sortBy[0] && {
          [`${sortBy[0]}`]: sortDesc[0],
        }),
      });
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
    getTotalCasesByState(state) {
      return {
        [states.CANCELED]: this.events.filter(
          (x) => x.state.name === states.CANCELED
        ).length,
        [states.VERIFICATION]: this.events.filter(
          (x) => x.state.name === states.VERIFICATION
        ).length,
        [states.MANAGEMENT]: this.events.filter(
          (x) => x.state.name === states.MANAGEMENT
        ).length,
        [states.REVISION]: this.events.filter(
          (x) => x.state.name === states.REVISION
        ).length,
        NONE: this.events.filter((x) => Boolean(x.state.name) !== true).length,
      }[state];
    },
    totalByState() {
      return {
        [states.CANCELED]: this.events.filter(
          (x) => x.state.name === states.CANCELED
        ).length,
        [states.VERIFICATION]: this.events.filter(
          (x) => x.state.name === states.VERIFICATION
        ).length,
        [states.MANAGEMENT]: this.events.filter(
          (x) => x.state.name === states.MANAGEMENT
        ).length,
        [states.REVISION]: this.events.filter(
          (x) => x.state.name === states.REVISION
        ).length,
        NONE: this.events.filter((x) => Boolean(x.state.name) !== true).length,
      };
    },
    totalByType() {
      let types = {};
      this.events.forEach((x) => {
        if (types[x.type.name] === undefined) {
          types[x.type.name] = this.events.filter(
            (event) => event.type.name === x.type.name
          ).length;
        }
      });

      return types;
    },
    editCase(id) {
      const selectedCase = this.events.find((x) => x.id === id);
      if (!selectedCase.isFemale) {
        this.$router.push({
          name: "humansRights.editCase",
          params: {
            id: id,
          },
        });
      } else {
        this.$router.push({
          name: "womenRights.case",
          params: {
            mode: "edit",
            id: id,
          },
        });
      }
    },
  },
  async mounted() {
    try{
      this.showLoader();
      this.dashboardData = (await getDashboardData(1)).data;
      this.hideLoader();
    }catch (e) {
      console.error(e);
    }
  },
};
</script>
